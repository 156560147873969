import React from 'react'
import Agtable from './ws'
import {Pagination,PaginationItem,
PaginationLink,Table,Modal,ModalBody,ModalFooter,ModalHeader,UncontrolledDropdown,UncontrolledButtonDropdown,Row,Form,FormGroup,Label,Input,Col,Card,CardBody,InputGroup,InputGroupText,FormText,Button, Dropdown,DropdownToggle, DropdownMenu, DropdownItem,Badge,ButtonGroup} from 'reactstrap'
import Mainalert from './alert'
import security from '../../../style/images/securepay.svg'
import piggybank from '../../../style/images/piggybank.svg'
import wallet from '../../../style/images/digital-wallet.svg'
import paxg from '../../../style/images/paxg.svg'
import xsicon from '../../../style/images/xs-icon.png'
import gold from '../../../style/images/gold.png'
import {Slide1,Slide2,Slide3,Slide4,Slide5,Slide6} from './slides/slide01'
import {Xslide1,Xslide2,Xslide3,Xslide4,Xslide5,Xslide6} from './slides/slide02'
import {Link,withRouter} from 'react-router-dom'
import NumericFormat from 'react-number-format';
import NumberFormat from 'react-number-format';
import SimpleLineIcon from 'react-simple-line-icons'
import axios from 'axios'
import Pag from './pag'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import cookie from 'react-cookies'
import Loader from '../loader'
import {HiOutlineInboxArrowDown,HiOutlineInboxArrowUp} from 'react-icons/hi2'
import {AiOutlineStock,AiOutlineClose} from 'react-icons/ai'
import {SiSoundcharts} from 'react-icons/si'
import {BsBorderStyle,BsViewList} from 'react-icons/bs'
import {GiSellCard,GiBuyCard,GiCardExchange,GiWallet,GiHelp} from 'react-icons/gi'
import {FaUser,FaAngleDoubleDown,FaSortAmountUp} from 'react-icons/fa'
import {FcComboChart} from 'react-icons/fc'
import {BsArrowLeft} from 'react-icons/bs'
import {PiHandCoins,PiHandshake,PiShuffle,PiWallet} from 'react-icons/pi'
import {CiSun,CiDark,CiSaveDown1,CiSaveUp1,CiIndent,CiBoxList,CiWallet,CiCircleChevDown,CiCircleChevUp} from 'react-icons/ci'
import {PiMinusSquare,PiPlusSquare} from 'react-icons/pi'
import Swap  from '../../panel/exchange/dark'
import Switch from "react-switch";
import {useTranslation,withTranslation} from 'react-i18next'
import { FaCaretUp,FaCaretDown } from "react-icons/fa6";
import { TickerTape } from "react-ts-tradingview-widgets";
import Usdt from '../../panel/tetherdark'
import { TfiBarChartAlt } from "react-icons/tfi";
import { BiSupport } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GoLaw } from "react-icons/go";
import { RiUserAddLine,RiUser3Line } from "react-icons/ri";
import { FaCrown } from "react-icons/fa6";
import { PieChart, Pie, Sector, Cell,ResponsiveContainer } from 'recharts';
import { UserAgent } from 'react-useragent';
import F from 'float'
import Pg from './pg.js'
const MySwal = withReactContent(Swal);

class Homepage extends React.Component{
  constructor(props){
    super(props);
    this.boolish = []
    this.bearish = []
    this.favorite = []
    this.state={
      filterlist: "1",
      cl1 :[],
      start : 0,
      next : 10,
      cl1 :[],
      socketedata :[],
      list : [],
      coins : [],
      response : [],
      coinname : '',
      coinname2 : '',
      sorted : [],
      se : [],
      c1 : [],
      eth : [],
      bnb : [],
      sd : [],
      paxg : [],
      kcs : [],
      c2 : [],
      activeIndex : 1,
      ca : false,
      drop1 : false,
      drop2 : false,
      circle : false,
      darkMode : false,
      mainmodal : true,
      markets : [],
      se : false,
      dp1 : false,
      dp2 : false,
      mainalertisopne : true,
      searchTerm : '',
      sd1 : '',

      sd2 : '',
      alertheader : '',
      mainalert : '',
      alertfooter : '',
      cdf : 5,
      loader : true,
      c4c2c2 : true,
      fx1 : true,
      fx2 : true,
      fx3 : true,
      fx4 : true,
      circularv:0,
      mainlist :[],
      asks :[],
      usdt :{"name":"USDT","symbol":"USDT","irprice":'',"irbuy":'',"irsell":'',"baseCurrency":"USDT","changeRate":"0.26"},
      sortedlist :[],
      b: true,
      c: true,
      d: true,
      e: true,
      bup : 'my-auto font-weight-bold increment',
      bdn : 'my-auto font-weight-bold reduction',
      filterlist : '1',
    }
    this.asks=[]
    this.bids=[]
    this.quotebalance = 0
    this.basebalance = 0
    this.forwardmarket = this.forwardmarket.bind(this)
    this.tickerlist =[
    {
      "proName": "BNBUSDT",
      "title": "BNB-USDT"
    },
    {
      "proName": "KCSUSDT",
      "title": "KCS-USDT"
    },
    {
      "proName": "PAXGUSDT",
      "title": "PAXG-USDT"
    },
    {
      "proName": "BTCUSDT",
      "title": "BTC-USDT"
    },
    {
      "proName": "ETHUSDT",
      "title": "ETH-USDT"
    }
    ]
  }
  setpaginationfirst = ()=>{
    this.setState({
      start : 0,
      next : 10
    })
  }


  setpaginationprevious = ()=>{

    let start = this.state.start
    let x = start - 10 > 10 ? start - 10 : 0
    this.setState({
      start : x ,
      next : x +10
    })
  }


  setpaginationnext = ()=>{
    let next = this.state.next
    let count = this.state.mainlist.length
    let x = next + 10 > count ? count : next + 10
    this.setState({
      start : x-10 ,
      next : x
    })
  }


  setpaginationlast = ()=>{
    let count = this.state.mainlist.length
    this.setState({
      start : count-10,
      next : count
    })
  }


  setquote = (item) =>{
    // console.log(item);

    if(item.baseCurrency === this.state.c2.baseCurrency){
      let id = item.id + 1

      return
    }
    this.setState({sd1 : '',sd2:'',searchTerm:'',a1 :'',res : '',coinname : item.baseCurrency ,baseurrency : item.baseCurrency, coinPrice : item.irbuy , fee1 : item.fee,c1 : item , drop1 : false})
    this.reset()
  }
  setbase = (item) =>{
    if(item.baseCurrency === this.state.markets.baseCurrency){
      let id = item.id + 1
      return
    }

    this.setState({sd1 : '',sd2:'',searchTerm:'',a1 :'',res : '',  coinname2 : item.baseCurrency ,baseurrency2 : item.baseCurrency , coinPrice2 : item.irsell , fee2 : item.fee,c2 : item , drop2 : false})

  }
  reset = ()=>{
    this.setState({
      res : '',
      a1 : ''
    })
  }
  onChange = (e)=>{
    console.log(e.target.value);
    this.setState({[e.target.name] : e.target.value})
    if(this.state.coinname === ''){
      this.setState({input1error : 'ارزی انتخاب نکرده اید'})
      return
    }
    if(this.state.coinname2 === ''){
        this.setState({input2error : 'ارزی انتخاب نکرده اید'})
      return
    }
   var c1 =  this.state.c1
   var c2  =  this.state.c2
   var a1 =   parseFloat(this.state.c1.irbuy)
   var a2  =  parseFloat(this.state.c2.irsell)
   var f2 =  0.004
   var all = e.target.value * a1
   var res = all/a2
   var fee = all * f2
   // var res = res - f2*res
   this.setState({res : res ,fee : fee ,all : all})
  }
  changeqm = (value,name)=>{
    this.setState({a1: value.floatValue})
    if(this.state.coinname === ''){
      this.setState({input1error : 'ارزی انتخاب نکرده اید'})
      return
    }
    if(this.state.coinname2 === ''){
        this.setState({input2error : 'ارزی انتخاب نکرده اید'})
      return
    }
   var c1 =  this.state.c1
   var c2  =  this.state.c2
   var a1 =   this.state.c1.irbuy
   var a2  =  this.state.c2.irsell
   var f2 =  0.004
   var all = value.floatValue * a1
   var res = all/a2
   var fee = all * f2
   // var res = res - f2*res
   this.setState({res : res ,all : all})
  }
  changebm = (value,name)=>{
    return
  }
componentWillMount(){

  const theme = document.body.getAttribute('class')
  if(theme === 'dark'){
    this.setState({darkMode : true , theme : 'dark'})
  }else{
    this.setState({darkMode : false , theme : 'light'})
  }

  this.getdata()
  // this.interval = setInterval(()=>this.getdata() , 10000)
}
componentWillUnmount(){
  clearInterval(this.interval)
}

getdata =()=>{
  axios.get('/api/market').then(response=>{
    this.setState({
      mainlist: response.data.markets ,
      sorted : response.data.markets ,
      markets : response.data.markets ,
      accounts : response.data.accounts ,
      usdt : response.data.usdt ,
      irr : response.data.IRR,
      mainalertisopen : response.data.mainalertisopen,
      alertheader : response.data.alertheader,
      mainalert : response.data.mainalert,
      alertfooter : response.data.alertfooter,
      })
      var xx = response.data.markets
      var sortedArray = xx.sort(function(a, b) { return b.last - a.last; });
      this.setState({sortedlist : sortedArray})
      this.boolish = response.data.boolish.slice(0,5)
      this.bearish = response.data.bearish.reverse().slice(0,5)
      this.favorite = response.data.markets.slice(0,5)
      this.props.setmessages(response.data.messages.public.concat(response.data.messages.private))
      this.setquote(response.data.IRR)
      this.setbase(response.data.markets[0])
    var list = response.data.markets
  let x = list.map(itm =>({
      ...response.data.accounts.find((item) => (itm.baseCurrency === item.slug) && (itm.availablebalance = item.availablebalance)),
      ...itm
  }));

let coins = response.data.markets.filter(item => item.quoteCurrency.includes('USDT'));
let usdt = response.data.usdt
usdt.rank = 1
coins.push(usdt)
this.setState({coins : coins})
var sortedArray = x.sort(function(a, b) { return b.availablebalance - a.availablebalance; });
  this.setState({merged : sortedArray})
    var sortedArray = x.sort(function(a, b) { return b.irprice - a.irprice; });
    const cd = [];
    const keys = response.data.accounts
    if(keys === undefined || keys.length === 0){
      return
    }
    keys.forEach( (key , index)=> {
      cd.push({
        name : key.slug,
        color : key.color,
        balance: key.availablebalance,
        availablebalance: key.availablebalance,
        balance: parseFloat(key.availablebalance) * parseFloat(key.irprice),
        value: parseFloat(key.availablebalance) * parseFloat(key.irprice),
      });
    });
    this.setState({chartdata : cd ,sortedlist :sortedArray })



  })
  this.filtered('1')

}
setdcf =()=>{
  if(this.state.cdf === 5){
    this.setState({cdf : 10})
  }else{
    this.setState({cdf : 5})
  }
}

onPieEnter = (_,index) => {
  const data = this.state.chartdata
    this.setState({
      activeIndex: index,
    });
    const found = data[index]
    this.setState({c : found})
  };


getexchangedata =()=>{
  if(!cookie.load('uid')){
    return
  }
  let base = this.state.c2.symbol
  let quote = this.state.c1.symbol
  let market = base+'-'+quote
  axios.get('/api/swap/exchange/'+market).then(response=>{
    this.setState({
      fee :response.data.fee ,
      baseprice :response.data.baseprice ,
      quoteprice :response.data.quoteprice ,
      base :response.data.base ,
      quote :response.data.quote ,
      level :response.data.level ,
    })
  })
}
getalllist = ()=>{

    this.setState({ ca : true})

}
setCryptoBuy =(i)=>{
  if(cookie.load('uid')){
  if(i.vc === 'VC_IRR'){
    this.props.history.push('/panel/depositirt/')
  }else{
    this.props.history.push('/panel/Exchange/buy/'+i.baseCurrency)
  }
}else{
  this.props.history.push('/auth/login')
}
}
setCryptoSell =(i)=>{
  if(cookie.load('uid')){
    this.props.history.push('/panel/Exchange/sell/'+i.baseCurrency)
  }else{
    this.props.history.push('/auth/login')
  }
}
setCryptoDeposit =(i)=>{

  if(cookie.load('uid')){
    this.props.history.push('/panel/Deposit/'+i.baseCurrency)

  }else{
    this.props.history.push('/auth/login')
  }
}
setCryptoWithdraw =(i)=>{
  if(cookie.load('uid')){
  if(i.vc === 'VC_IRR'){
    this.props.history.push('/panel/withdrawirt/')
  }else{
    this.props.history.push('/panel/withdraw/'+i.baseCurrency)
  }
}else{
  this.props.history.push('/auth/login')
}
}
toggle1 = ()=>{
  this.setState({drop1 : !this.state.drop1})
}
toggle2 = ()=>{
  this.setState({drop2 : !this.state.drop2})
}
check = () =>{
  if(cookie.load('uid')){
    this.props.history.push('/auth/login')
    return
  }
}
convert = ()=>{

  var c1 = this.state.c1
  var c2 = this.state.c2
  this.setState({c1 : c2 , c2  :c1  ,a1 : '' , res : ''})
  // var amount = this.state.res
  // var feeamount = this.state.a1 * c2.irbuy
  // var x = feeamount / c1.irsell
  // var m = x - (0.004 * x)
  // this.setState({
  //   res : m , a1 : amount
  // })
}
searchUpdated = (e)=> {
   this.setState({searchTerm: e.target.value.toUpperCase()})
 }
search = (e)=> {
  // if(e.target.value.length <3){
  //   return
  // }
  axios.get('/api/market/search/'+e.target.value).then(response=>{
    this.setState({mainlist : response.data.data})
  })
 }
 componentDidMount(){
   setTimeout(()=>this.setState({loader : false}), 1000);


 }
 forwardmarket =(i)=>{
   return '/trade/'+i.baseCurrency+'-USDT'
}
 forwardbuysell =(i)=>{

    if(cookie.load('uid')){
   return '/panel/exchange/buy/'+i.baseCurrency
 }else{
    return '/auth/login'
 }
 }
 serach1 =(e)=>{
   this.setState({sd1 : e.target.value.toUpperCase()})
 }
 serach2 =(e)=>{
   this.setState({sd2 : e.target.value.toUpperCase()})
 }
 handleChange = (checked) =>{
     this.setState({ checked : !this.state.checked });
   }

mergedsell =()=>{
  this.setState({b : !this.state.b})
  const x = this.state.sorted.filter(item => item.baseCurrency.includes(this.state.searchTerm));
  if(this.state.b === true)
  {
    var sortedArray = x.sort(function(a, b) { return b.irprice - a.irprice; })
  }else{
    var sortedArray = x.sort(function(a, b) { return a.irprice - b.irprice; })
  }

  this.setState({sorted : sortedArray, ca : true})
}

mergedbuy =()=>{
  this.setState({c : !this.state.c})
  const x = this.state.sorted.filter(item => item.baseCurrency.includes(this.state.searchTerm));
  var sortedArray = x.sort(function(a, b) { return b.irprice - a.irprice; });
}
filtered = (i)=>{
  this.setState({filterlist : i})
  if(i === '1'){
    if(this.state.fx1 === true){
      var x = this.state.mainlist
      var sortedArray = x.sort(function(a, b) { return a.last - b.last; });
      this.setState({sortedlist : sortedArray , fx1 : !this.state.fx1, b : !this.state.b})
    }else{
      var x = this.state.mainlist
      var sortedArray = x.sort(function(a, b) { return b.last - a.last; });
      this.setState({sortedlist : sortedArray , fx1 : !this.state.fx1 , b : !this.state.b})
    }
  }
  if(i === '2'){
    if(this.state.fx1 === true){
      var x = this.state.mainlist
      var sortedArray = x.sort(function(a, b) { return a.last - b.last; });
      this.setState({sortedlist : sortedArray , fx1 : !this.state.fx1, b : !this.state.b})
    }else{
      var x = this.state.mainlist
      var sortedArray = x.sort(function(a, b) { return b.last - a.last; });
      this.setState({sortedlist : sortedArray , fx1 : !this.state.fx1 , b : !this.state.b})
    }
  }

  if(i === '3'){
    if(this.state.fx3 === true){
      var x = this.state.markets
      var sortedArray = x.sort(function(a, b) { return parseFloat(b.changeRate) - parseFloat(a.changeRate); });
      this.setState({sortedlist : sortedArray , fx3 : !this.state.fx3, c : !this.state.c})
    }else{
      var x = this.state.markets
      var sortedArray = x.sort(function(a, b) { return parseFloat(a.changeRate) - parseFloat(b.changeRate); });
      this.setState({sortedlist : sortedArray , fx3 : !this.state.fx3, c : !this.state.c})
    }
  }
  if(i === '4'){
    if(this.state.fx4 === true){
      var x = this.state.markets
      var sortedArray = x.sort(function(a, b) { return b.volValue - a.volValue; });
      this.setState({sortedlist : sortedArray , fx4 : !this.state.fx4 , e : !this.state.e})
    }else{
      var x = this.state.markets
      var sortedArray = x.sort(function(a, b) { return a.volValue - b.volValue; });
      this.setState({sortedlist : sortedArray , fx4 : !this.state.fx4, e : !this.state.e})
    }
  }
}

render(){
  const data = this.state.chartdata

  const gradiant = {
    "font-size": "50px",
    "background": "radial-gradient(at left top, white 0%, #ffffac 8%, #d1b464 25%, #dec99a 62.5%, #5d4a1f 100%)",
    "background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "display": "inline-block",
  }
  const feestyle = {
    "font-size": "50px",
    "background": "radial-gradient(at left top, white 0%, #ffffac 8%, #d1b464 25%, #dec99a 62.5%, #5d4a1f 100%)",
    "background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "display": "inline-block",
  }
  const {t} = this.props
  const theme = document.body.getAttribute('class')
  const coins = this.state.markets

  const f = this.state.sortedlist
  const o = this.state.markets.filter(item => item.quoteCurrency === 'USDT');
  const co = this.state.coins
  const d1 = this.state.mainlist.filter(item => item.baseCurrency.includes(this.state.sd1));
  const d2 = this.state.mainlist.filter(item => item.baseCurrency.includes(this.state.sd2));
  return(
    <>

    <div className='d-block d-sm-none px-1 pt-3'>
        {cookie.load('uid') ?

        <div className=''>

        </div>

         :
        <Row xs='2' className='border-light xs-login-card bg-transparent radius rtl'>
        <Col xs='8' className='right-login-col'>

         <h6 className='ml-1 mt-3'>
           {t('Rapitex')}
         </h6>
         <small className='ml-1 '>
           {t('cryptocurrency exchange platform')}
         </small>
         </Col>
          <Col xs='4' className='left-login-col ltr'>
            <img width='70px' src={xsicon} className='xs-icon-signup  mr-auto' color='#000' size='1.5rem' />
          </Col>
          <Col xs='6'>
          <Link to='/auth/signup' className='badg-dark-signup w-100 mt-5' color='dark' >
              <span className='text-medium'>{t('signup')}</span>
              <BsArrowLeft size='1.3rem' className='arrow-signup mr-auto' />
          </Link>
          </Col>
          <Col xs='6'>
          <Link to='/auth/login' className='badg-light-green w-100 mt-5 py-2 px-3' color='dark' >
            <span className='text-medium'>{t('login')}</span>
              <BsArrowLeft color='#000' size='1.3rem' className='arrow-signup mr-auto' />
          </Link>
          </Col>
        </Row>
        }

        <div className='my-3 xs-slider-box mt-5 bg-transparent border-light radius'>
            <Carousel showIndicators={false}  showStatus={false} showThumbs={false} transitionTime={1000} emulateTouch={true} showArrows={false} stopOnHover={true} swapable={true} infiniteLoop autoPlay={false}  interval={5000}>
              <div className='xs-slide'>
                <Row xs='4' className='text-center icon-row'>
                <Col xs='3'>
                  <Link to='/panel/wallet'>
                    <div className='mx-2 text-center radius' >
                      <PiWallet size='1.5rem' className='d-wallet-icon'/>
                    </div>
                    <span className='text-dark mt-3 rtl text-center text-white-fix'>دارایی ها</span>

                  </Link>
                </Col>
                  <Col xs='3'>
                    <Link to='/panel/Exchange/buy/BTC'>
                      <div className='mx-2 text-center radius' >
                        <PiShuffle size='1.5rem' className='d-exchange-icon' />
                      </div>
                        <span className='text-dark mt-3 rtl text-center text-white-fix'>تبدیل سریع</span>
                    </Link>
                  </Col>
                  <Col xs='3'>
                    <Link to='/panel/withdrawirt'>
                      <div className='mx-2 text-center radius' >
                        <PiHandshake size='1.5rem' className='d-sell-icon'/>
                      </div>
                        <span className='text-dark mt-3 rtl text-center text-white-fix'>برداشت ریالی</span>
                    </Link>
                  </Col>

                  <Col xs='3'>
                    <Link to='/panel/depositirt'>
                    <div className='mx-2 text-center radius'>
                        <PiHandCoins size='1.5rem' className='text-center d-exchange-icon' />
                      </div>
                                              <span className='text-dark mt-3 text-center'>واریز ریال</span>
                    </Link>
                  </Col>
                  <Col xs='3' className='mt-4'>
                    <Link to='/trade/BTC-USDT'>
                      <div className=' text-center  radius' >
                        <CiIndent size='1.5rem' className='d-buy-icon' />
                      </div>
                                              <span className='text-dark mt-3 rtl text-center text-white-fix'>معاملات</span>
                    </Link>
                  </Col>
                  <Col xs='3' className='mt-4'>
                    <Link to='/panel/transactions'>
                      <div className='text-center radius' >
                        <CiBoxList size='1.5rem' className='d-exchange-icon' />
                      </div>
                                            <span className='text-dark mt-3 rtl mx-auto text-center'>{t('transactions')}</span>
                    </Link>
                  </Col>
                  <Col xs='3' className='mt-4'>
                    <Link to='/panel/withdrawall'>
                      <div className='mx-2 text-center radius' >
                        <CiSaveUp1 size='1.5rem' className='font-weight-bold d-wallet-icon'/>
                      </div>
                                              <span className='text-dark mt-3 rtl text-center text-white-fix'>برداشت ارز</span>
                    </Link>
                  </Col>
                  <Col xs='3' className='mt-4'>
                    <Link to='/panel/depositcrypto'>
                      <div className='mx-2 text-center radius' >
                        <CiSaveDown1 size='1.5rem' className='font-weight-bold  d-sell-icon'/>
                      </div>
                                              <span className='text-dark mt-3 rtl text-center text-white-fix'>واریز ارز</span>
                    </Link>
                  </Col>

                </Row>
              </div>
              <div className='xs-slide'>
              <Row xs='4' className='icon-row'>
                <Col xs='3' sm='3'>
                  <Link to='/currencies'>
                    <div className='text-center radius' >
                      <TfiBarChartAlt size='1.5rem' className='font-weight-bold d-buy-icon' />
                    </div>
                                          <span className='text-dark mt-3 mx-auto'>مارکت ها</span>
                  </Link>
                </Col>
                <Col xs='3' sm='3'>
                  <Link to='/fees'>
                    <div className='mx-2 text-center radius' >
                      <GiCardExchange size='1.5rem' className='font-weight-bold d-exchange-icon' />
                    </div>
                                          <span className='text-dark mt-3 rtl text-center text-white-fix'>کارمزد</span>
                  </Link>
                </Col>
                <Col xs='3' className=''>
                  <Link to='/panel/transactions'>
                    <div className='text-center radius' >
                      <CiBoxList size='1.5rem' className='d-exchange-icon' />
                    </div>
                                          <span className='text-dark mt-3 rtl mx-auto text-center'>{t('transactions')}</span>
                  </Link>
                </Col>
                <Col xs='3' sm='3'>
                  <Link to='/about-us'>
                    <div className='mx-2 text-center radius' >
                      <AiOutlineInfoCircle size='1.5rem' className='d-wallet-icon'/>
                    </div>
                                          <span className='text-dark mt-3 rtl text-center text-white-fix'>درباره ما</span>
                  </Link>
                </Col>
                <Col xs='3' sm='3' className='mt-4'>
                  <Link to='/Terms-and-Condition'>
                    <div className=' mx-2 text-center  radius' >
                      <GoLaw size='1.5rem' className='d-buy-icon' />
                    </div>
                                          <span className='text-dark mt-3 rtl text-center text-white-fix'>قوانین ما</span>
                  </Link>
                </Col>
                <Col xs='3' sm='3' className='mt-4'>
                  <Link to='/FAQ'>
                    <div className='mx-2 text-center radius' >
                      <GiHelp size='1.5rem' className='d-wallet-icon'/>
                    </div>
                                          <span className='text-dark mt-3 rtl text-center text-white-fix'>راهنما</span>
                  </Link>
                </Col>
                <Col xs='3' sm='3' className='mt-4'>
                  <Link to='/auth/login'>
                    <div className='mx-2 text-center radius' >
                      <RiUser3Line size='1.5rem' className='d-exchange-icon' />
                    </div>
                                          <span className='text-dark mt-3 rtl text-center text-white-fix'>ورود</span>
                  </Link>
                </Col>
                <Col xs='3' sm='3' className='mt-4'>
                  <Link to='/auth/signup'>
                    <div className='mx-2 text-center radius' >
                      <RiUserAddLine size='1.5rem' className='text-dark d-sell-icon'/>
                    </div>
                                          <span className='text-dark rtl text-center text-white-fix'>ثبت نام</span>
                  </Link>
                </Col>

              </Row>
              </div>
            </Carousel>

        </div>
        <Link   to={cookie.load('uid') ? '/panel/rewards' : '/rewards' }  className='mx-4 mt-5 py-2 rewards-badge' >{t('rewards&campaigns')}</Link>


        <div className=' border-light my-3 xs-slider-box bg-transparent  radius'>
            <Carousel showThumbs={false} transitionTime={1000} emulateTouch={true} showArrows={false} stopOnHover={true} swapable={true} infiniteLoop autoPlay={false}  interval={5000}>

                <div className='xs-slide'>
                  <Row  data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" xs='3' className=''>
                  {this.state.markets.slice(0,3).map(i=>
                  <Col  className='my-auto' xs='4'>
                    <div className='d-flex'>
                    <span className={i.baseCurrency+'-icon my-auto mx-auto'}></span>
                    </div>
                    <div className='d-flex text-center'>
                    <small className='text-small  ml-auto mt-2 d-block'>{i.baseCurrency}</small>
                    <small className={i.changeRate > 0 ? 'text-success mr-auto ml-2 mt-2' : 'text-danger mr-auto ml-2 mt-2' }>{i.changeRate} </small>
                    </div>
                    {i.changeRate > 0 ?
                      <small className=''>
                      <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                      </small>
                    :
                    <small className=''>
                    <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                    </small>
                  }
                  </Col>
                  )}
                  </Row>
                </div>
                <div className='xs-slide'>
                <Row  data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" xs='3' className=''>
                {this.state.markets.slice(3,6).map(i=>
                <Col  className='my-auto' xs='4'>
                  <div className='d-flex'>
                  <span className={i.baseCurrency+'-icon my-auto mx-auto'}></span>
                  </div>
                  <div className='d-flex text-center'>
                  <small className='text-small  ml-auto mt-2 d-block'>{i.baseCurrency}</small>
                  <small className={i.changeRate > 0 ? 'text-success mr-auto ml-2 mt-2' : 'text-danger mr-auto ml-2 mt-2' }>{i.changeRate} </small>
                  </div>
                  {i.changeRate > 0 ?
                    <small className=''>
                    <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                    </small>
                  :
                  <small className=''>
                  <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                  </small>
                }
                </Col>
                )}
                </Row>
                </div>
                <div className='xs-slide'>
                <Row  data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" xs='3' className=''>
                {this.state.markets.slice(0,3).map(i=>
                <Col  className='my-auto' xs='4'>
                  <div className='d-flex'>
                  <span className={i.baseCurrency+'-icon my-auto mx-auto'}></span>
                  </div>
                  <div className='d-flex text-center'>
                  <small className='text-small  ml-auto mt-2 d-block'>{i.baseCurrency}</small>
                  <small className={i.changeRate > 0 ? 'text-success mr-auto ml-2 mt-2' : 'text-danger mr-auto ml-2 mt-2' }>{i.changeRate} </small>
                  </div>
                  {i.changeRate > 0 ?
                    <small className=''>
                    <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                    </small>
                  :
                  <small className=''>
                  <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                  </small>
                }
                </Col>
                )}
                </Row>
                </div>
                <div className='xs-slide'>
                <Row  data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" xs='3' className=''>
                {this.state.markets.slice(6,9).map(i=>
                <Col  className='my-auto' xs='4'>
                  <div className='d-flex'>
                  <span className={i.baseCurrency+'-icon my-auto mx-auto'}></span>
                  </div>
                  <div className='d-flex text-center'>
                  <small className='text-small  ml-auto mt-2 d-block'>{i.baseCurrency}</small>
                  <small className={i.changeRate > 0 ? 'text-success mr-auto ml-2 mt-2' : 'text-danger mr-auto ml-2 mt-2' }>{i.changeRate} </small>
                  </div>
                  {i.changeRate > 0 ?
                    <small className=''>
                    <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                    </small>
                  :
                  <small className=''>
                  <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                  </small>
                }
                </Col>
                )}
                </Row>
                </div>
                <div className='xs-slide'>
                <Row  data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" xs='3' className=''>
                {this.state.markets.slice(9,12).map(i=>
                <Col  className='my-auto' xs='4'>
                  <div className='d-flex'>
                  <span className={i.baseCurrency+'-icon my-auto mx-auto'}></span>
                  </div>
                  <div className='d-flex text-center'>
                  <small className='text-small  ml-auto mt-2 d-block'>{i.baseCurrency}</small>
                  <small className={i.changeRate > 0 ? 'text-success mr-auto ml-2 mt-2' : 'text-danger mr-auto ml-2 mt-2' }>{i.changeRate} </small>
                  </div>
                  {i.changeRate > 0 ?
                    <small className=''>
                    <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                    </small>
                  :
                  <small className=''>
                  <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                  </small>
                }
                </Col>
                )}
                </Row>
                </div>
                <div className='xs-slide'>
                  <Row  data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" xs='3' className=''>
                  {this.state.markets.slice(12,15).map(i=>
                    <Col  className='my-auto' xs='4'>
                      <div className='d-flex'>
                      <span className={i.baseCurrency+'-icon my-auto mx-auto'}></span>
                      </div>
                      <div className='d-flex text-center'>
                      <small className='text-small  ml-auto mt-2 d-block'>{i.baseCurrency}</small>
                      <small className={i.changeRate > 0 ? 'text-success mr-auto ml-2 mt-2' : 'text-danger mr-auto ml-2 mt-2' }>{i.changeRate} </small>
                      </div>
                      {i.changeRate > 0 ?
                        <small className=''>
                        <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                        </small>
                      :
                      <small className=''>
                      <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                      </small>
                    }
                    </Col>
                  )}
                  </Row>
                </div>
            </Carousel>

        </div>
          <div className='xs-slider-500 mt-5 bg-transparent px-2'>
            <Carousel showThumbs={false} transitionTime={1000} emulateTouch={true} showArrows={false} stopOnHover={true} swapable={true} infiniteLoop autoPlay={false}  interval={5000}>
              <div  className=''>
                <div className='top-market-list-card'>
                 <h6 className='card-header-cr'>{t('top-boolish')}</h6>
                 <div>
                 {this.boolish.map(i=>
                   <div  className='my-auto' xs='12'>
                     <div className='pb-4 ltr justify-content-space-between d-flex'>
                       <div className='ltr'>
                         <div className='ltr d-flex'>
                         <p className={i.baseCurrency+'-icon'}></p>
                         <div>
                         <b className='text-small   ml-3  d-block'>{i.baseCurrency}</b>
                         <p className='ml-3 mt-2'>{i.name}</p>
                         </div>
                         </div>

                       </div>
                       <div className='rtl'>
                       {i.changeRate > 0 ?
                         <b className='text-success d-block ml-auto'>
                         <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                         </b>
                       :
                       <b className='text-danger d-block ml-auto'>
                       <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                       </b>
                     }
                         {i.changeRate > 0 ?
                         <small className='text-success mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 )} +</small>:
                         <small className='text-danger mr-auto ml-2 mt-2'>  {F.floor((i.changeRate * 100),4 ) * -1} %</small> }
                       </div>
                     </div>

                 </div>)}
                 </div>
                </div>
              </div>
              <div  className=''>
                <div className='top-market-list-card'>
                 <h6 className='card-header-cr'>{t('top-bearish')}</h6>
                 {this.bearish.map(i=>
                   <div  className='my-auto' xs='12'>
                     <div className='pb-4 ltr justify-content-space-between d-flex'>
                       <div className='ltr'>
                         <div className='ltr d-flex'>
                         <p className={i.baseCurrency+'-icon'}></p>
                         <div>
                         <b className='text-small   ml-3  d-block'>{i.baseCurrency}</b>
                         <p className='ml-3 mt-2'>{i.name}</p>
                         </div>
                         </div>

                       </div>
                       <div className='rtl'>
                       {i.changeRate > 0 ?
                         <b className='text-success d-block ml-auto'>
                         <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                         </b>
                       :
                       <b className='text-danger d-block ml-auto'>
                       <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                       </b>
                     }
                     {i.changeRate > 0 ?
                     <small className='text-success mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 )}  +</small>:
                     <small className='text-danger mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 ) * -1} -</small> }
                     </div>
                     </div>

                 </div>)}
                </div>
              </div>
              <div  className=''>
                <div className='top-market-list-card'>
                 <h6 className='card-header-cr'>{t('top-favour')}</h6>
                 {this.favorite.map(i=>
                   <div  className='my-auto' xs='12'>
                     <div className='pb-4 ltr justify-content-space-between d-flex'>
                       <div className='ltr'>
                         <div className='ltr d-flex'>
                         <p className={i.baseCurrency+'-icon'}></p>
                         <div>
                         <b className='text-small   ml-3  d-block'>{i.baseCurrency}</b>
                         <p className='ml-3 mt-2'>{i.name}</p>
                         </div>
                         </div>

                       </div>
                       <div className='rtl'>
                       {i.changeRate > 0 ?
                         <b className='text-success d-block ml-auto'>
                         <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                         </b>
                       :
                       <b className='text-danger d-block ml-auto'>
                       <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                       </b>
                     }
                     {i.changeRate > 0 ?
                     <small className='text-success mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 )}  +</small>:
                     <small className='text-danger mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 ) * -1} -</small> }
                     </div>
                     </div>

                 </div>)}
                </div>
              </div>
            </Carousel>
          </div>
        <div className='xs-slider-200 mt-5 bg-transparent'>
            <Carousel showThumbs={false} transitionTime={1000} emulateTouch={true} showArrows={false} stopOnHover={true} swapable={true} infiniteLoop autoPlay={false}  interval={5000}>
              <Xslide3/>
              <Xslide1/>
              <Xslide2/>
              <Xslide4/>
              <Xslide5/>
              <Xslide6/>
              </Carousel>
          </div>
        <div class="home scrollHider">
        <div class="home menuBox">
                <p className={this.state.filterlist ===  "1" ? 'active-tab my-auto pointer' : "pointer my-auto"  }  onClick={()=>this.filtered('1')}>{t("قیمت")}</p>

                <p className={this.state.filterlist ===  "2" ? 'active-tab my-auto pointer' : "pointer my-auto"  }  onClick={()=>this.filtered('3')}>{t("نرخ افزایش")}</p>

                <p className={this.state.filterlist ===  "3" ? 'active-tab my-auto pointer' : "pointer my-auto"  }  onClick={()=>this.filtered('3')}>{t("میزان افرایش")}</p>

                <p className={this.state.filterlist ===  "4" ? 'active-tab my-auto pointer' : "pointer my-auto"  }  onClick={()=>this.filtered('4')}>{t("سهم بازار")}</p>
        </div>
        </div>
        <div className='divider' />
        <div className='crypto-rows xs-list-markets custom-overflow'>

        {f.slice(0,this.state.cdf).map(n=>
          <Link to={this.forwardmarket(n)}>
          <tr className='coin-list-row rtl'>
            <td xs='1'>
            <span className='my-auto d-flex'>
              <p className={n.baseCurrency+ '-icon mr-2 my-auto'}></p>
              <p className='mr-4 mt-2' >{n.baseCurrency}</p>
            </span>
            </td>


            <td className='my-auto '>
            <NumberFormat displayType='text' className='my-auto ' value={this.state.checked === false ? n.sell : n.irsell}  thousandSeparator=','/>
              <p>{n.changeRate > 0 ?
                <small className="text-small text-success text-center coin-name" >  %{F.floor(n.changeRate * 100) + '+'}</small> :
                <small className="text-small text-warning text-center m-auto coin-name" >  %{F.floor(n.changeRate * 100) * -1 + ' -'}</small>}</p>
            </td>


            <td>
              <Link className='px-3 w-100 mr-auro bdage-market-success' to={'/panel/trade/'+n.symbolName} >
                {t('trade')}
              </Link>
            </td>
          </tr>
          </Link>
        )}

        </div>
        {coins.length > 0 ?
          <div className='text-center my-4'>{this.state.cdf === 5 ?
            <CiCircleChevDown onClick={this.setdcf} className='plus-home-icon' size='1.5rem' /> :
            <CiCircleChevUp onClick={this.setdcf} className='plus-home-icon' size='1.5rem' />}
          </div>
        : <></> }
        <div className='py-5 px-1 mt-5'>
          <Row xs='2' className='border-light bg-transparent p-x-2 py-3 radius'>
            <Col xs='9'>
              <div className=''>
                <h6 className='mt-2 mr-auto'>{t('invite-friend-text')}</h6>
              </div>
            </Col>
            <Col xs='3'>
              <Link to='/auth/signup'>
              <div className='gradiant-icon px-2'>
                <i class="fa fa-crown" style={gradiant}></i>
              </div>
              </Link>
            </Col>
          </Row>
          <Row xs='2' className='border-light bg-transparent my-4 px-2 py-3 radius'>

            <Col xs='3'>
              <div className='gradiant-icon px-2 d-flex'>
                <span style={{"font-size" : "30px"}} className='fa-percent'>0.2</span>
              </div>
            </Col>
            <Col xs='9'>
              <div className='rtl px-3'>
                <h6 className='rtl '>{t('fee-text')}</h6>
                <Link to='/fees' className='rtl '>{t('fee-start-text')}</Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {this.state.loader === true ?
        <>
          <div className='loader-home py-5'>
            <h3 className='text-center m-auto mt-auto'><Loader /></h3>
          </div>
        </> :
        <>

       <div className='d-none d-sm-block'>

        <Row   className='rtl hasbg' lg='1' md='1' xl='2' sm='1' xs='1'>
        <Col xl='7'>
          <div className='px-2 jumbtron text-white  mx-auto'>
          <div className='header-sec-01'>
          <h1 className=' mr-auto header-text font-weight-bold  mt-5'> پلتفرم  </h1>
          <h1 className=' mx-2 header-text font-weight-bold  mt-5'> معاملاتی  </h1>
          <h1 className='ml-auto header-text text-warning font-weight-bold  mt-5'> رپیتکس
          </h1>
          </div>
          <div className='mx-auto d-block text-warning d-sm-none'>
          <h1 className='rapitex  font-weight-bold  mt-5 mr-3'> رپیتکس
          </h1>
          <p className='y-slogan'>پلتفرم معاملاتی ارزهای دیجیتال</p>
          </div>
          <h2 className='header-text-2  font-weight-bold  mt-3'> هر لحظه در آستانه موفقیت
          </h2>
          <h4 className='header-text-3  mt-3'>تجربه رکوردی جدید در سرعت سفارشات
        </h4>
          </div>
          {!cookie.load('uid') ?
          <div className='text-center buttons mx-auto'>
            <Button color='warning'  className='main-buttun text-center'>
              <div className='d-flex text-center rtl'>
              <Link to='/auth/login' className=' mr-auto'>
                <span className='mr-auto ml-2 font-weight-bold my-auto'>ورود
                </span>
              </Link>
                <span className='mx-3 my-auto'>یا
                </span>
                <Link to='/auth/signup' className=''>
                <span className='mx-3 font-weight-bold ml-auto'>ثبت نام
                </span>
                </Link>
                <span className='mr-auto my-auto'><FaUser  color='#000' size='1rem'/>
                </span>
              </div>
            </Button>

          </div>
          :
            <div className='text-center buttons mx-auto'>
          <Link to='/panel/Dashboard' className=''>
            <Button color='warning'  className='main-buttun text-center'>


                <span className='mx-3 font-weight-bold ml-auto'>{t('Enter User Panel')}
                </span>


            </Button>
          </Link>
        </div> }
        </Col>
        </Row>
        <div className='ticker' >
        <TickerTape displayMode='regular' largeChartUrl='https://rapitex.com/trade/BTC-USDT'  symbols={this.tickerlist} isTransparent={true} theme='dark' />
        </div>


        <Row xl='1' className='text-center'>
        <Col lg={12} xl={{size:10 , order:2}} md={12} className='mx-auto mt-5' >
          <Usdt
            usdtirprice = {this.state.usdt.irprice}
            usdtmin = {this.state.usdt.min}
            usdtmax = {this.state.usdt.max}
            usdtday = {this.state.usdt.day}
            changeRate = {this.state.usdt.changeRate}
           />
           <Row className='mt-5' xs='1' sm='1' md='3' lg='3' xl='3'>

             <Col xs='12' sm='12' md='4' lg='4' xl='4' className=''>
               <div className='top-market-list-card'>
                <h6 className='card-header-cr'>{t('top-boolish')}</h6>
                <Row>
                {this.boolish.map(i=>
                  <Col  className='my-auto' xs='12'>
                    <div className='pb-4 ltr justify-content-space-between d-flex'>
                      <div className='ltr'>
                        <div className='ltr d-flex'>
                        <p className={i.baseCurrency+'-icon'}></p>
                        <div>
                        <b className='text-small   ml-3  d-block'>{i.baseCurrency}</b>
                        <p className='ml-3 mt-2'>{i.name}</p>
                        </div>
                        </div>

                      </div>
                      <div className='rtl'>
                      {i.changeRate > 0 ?
                        <b className='text-success d-block ml-auto'>
                        <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                        </b>
                      :
                      <b className='text-danger d-block ml-auto'>
                      <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                      </b>
                    }
                        {i.changeRate > 0 ?
                        <small className='text-success mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 )} +</small>:
                        <small className='text-danger mr-auto ml-2 mt-2'>  {F.floor((i.changeRate * 100),4 ) * -1} %</small> }
                      </div>
                    </div>

                </Col>)}
                </Row>
               </div>
             </Col>
             <Col xs='12' sm='12' md='4' lg='4' xl='4' className=''>
               <div className='top-market-list-card'>
                <h6 className='card-header-cr'>{t('top-bearish')}</h6>
                {this.bearish.map(i=>
                  <Col  className='my-auto' xs='12'>
                    <div className='pb-4 ltr justify-content-space-between d-flex'>
                      <div className='ltr'>
                        <div className='ltr d-flex'>
                        <p className={i.baseCurrency+'-icon'}></p>
                        <div>
                        <b className='text-small   ml-3  d-block'>{i.baseCurrency}</b>
                        <p className='ml-3 mt-2'>{i.name}</p>
                        </div>
                        </div>

                      </div>
                      <div className='rtl'>
                      {i.changeRate > 0 ?
                        <b className='text-success d-block ml-auto'>
                        <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                        </b>
                      :
                      <b className='text-danger d-block ml-auto'>
                      <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                      </b>
                    }
                    {i.changeRate > 0 ?
                    <small className='text-success mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 )}  +</small>:
                    <small className='text-danger mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 ) * -1} -</small> }
                    </div>
                    </div>

                </Col>)}
               </div>
             </Col>
             <Col xs='12' sm='12' md='4' lg='4' xl='4' className=''>
               <div className='top-market-list-card'>
                <h6 className='card-header-cr'>{t('top-favour')}</h6>
                {this.favorite.map(i=>
                  <Col  className='my-auto' xs='12'>
                    <div className='pb-4 ltr justify-content-space-between d-flex'>
                      <div className='ltr'>
                        <div className='ltr d-flex'>
                        <p className={i.baseCurrency+'-icon'}></p>
                        <div>
                        <b className='text-small   ml-3  d-block'>{i.baseCurrency}</b>
                        <p className='ml-3 mt-2'>{i.name}</p>
                        </div>
                        </div>

                      </div>
                      <div className='rtl'>
                      {i.changeRate > 0 ?
                        <b className='text-success d-block ml-auto'>
                        <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                        </b>
                      :
                      <b className='text-danger d-block ml-auto'>
                      <NumberFormat value={i.irbuy} displayType='text'  thousandSeparator=',' />
                      </b>
                    }
                    {i.changeRate > 0 ?
                    <small className='text-success mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 )}  +</small>:
                    <small className='text-danger mr-auto ml-2 mt-2'> % {F.floor((i.changeRate * 100),4 ) * -1} -</small> }
                    </div>
                    </div>

                </Col>)}
               </div>
             </Col>
           </Row>
        </Col>
        </Row>

        <Row xl='1' className='text-center'>

          <Col lg={12} xl={{size:11}} md={12} className='mx-auto' >

          <h5 className='text-ccenter mt-5 mb-3'>تبدیل سریع ارز ها
          </h5>
          <Row xl='3' lg='3' md='3' className='exchange-section rtl'>
            <Col xl='5' lg='5' md={12} className='' >
            <div className='text-center my-auto' id='calc'>
            <FormGroup>
            <InputGroup size='lg'>
              <UncontrolledDropdown >
                <DropdownToggle className='input-custome-button'>
                  <div className='d-flex' >
                    <span className='my-auto text-medium'>پرداخت میکنم</span>
                      <span className={this.state.c1.baseCurrency+ '-icon mr-2'} ></span>
                    <span className='ml-1 mr-auto my-auto text-medium'>
                      {this.state.c1.baseCurrency}
                    </span>
                    <FaAngleDoubleDown size='0.7rem' className='text-muted font-weight-light d-none d-sm-block mr-auto mr-auto my-auto' />
                   </div>
                 </DropdownToggle>
               <DropdownMenu right className='px-2 swap-dropdown bg-white '>
                  <div className='bg-light market-input-dark d-flex' >
                    <input type='search' value={this.state.sd1} onChange={this.serach1} dir='ltr'  className='ltr form-control' />
                  </div>
                <DropdownItem divider className='' />
                <div className=''>
                <DropdownItem  className='d-flex py-2' onClick={()=>this.setquote(this.state.irr)} >
                <span className='ml-5'>
                  <span className='irr-icon' ></span>
                </span>
                <span className='ml-2 '>
                <NumericFormat value={1}   thousandSeparator="," displayType="text"/>
                </span>
                <span className='mr-auto '>
                  IRR
                </span>

                </DropdownItem>

                {d1.map(item=>
                  <DropdownItem  className='d-flex py-2' onClick={()=>this.setquote(item)} >
                  <span className='ml-5'>
                    <span className={item.baseCurrency+ '-icon'} ></span>
                  </span>
                  <span className='ml-2 '>
                  <NumericFormat value={item.irbuy}   thousandSeparator="," displayType="text"/>
                  </span>
                  <span className='mr-auto '>
                    {item.baseCurrency}
                  </span>

                  </DropdownItem>
                )}
                </div>
              </DropdownMenu>
                </UncontrolledDropdown>
              <NumberFormat
                className='swap-input form-control '
                name='quoteamount'
                value = {this.state.a1}
                min='0'
                inputMode="decimal"
                onValueChange = {(values, sourceInfo)=>this.changeqm(values,'a1')}
                ref="quoteamount"
                thousandSeparator=','
                />
              </InputGroup>

              <div className='d-flex'>
              {this.state.c1.baseurrency === 'IRR' ? <small className='text-primary rtl' >{this.state.x}</small>: <></> }

              </div>
              </FormGroup>
            </div>


            </Col>
            <Col xl='2' lg='2'>
            <div >
            <span className='exchange-icon mx-auto pointer' onClick={this.convert}></span>
            </div>
            </Col>
            <Col xl='5' lg='5' md={12} className=''>
            <div className='py-auto' id='calc'>
            <FormGroup>
            <InputGroup size='lg'>
                <UncontrolledDropdown >
                <DropdownToggle className='input-custome-button'>
                    <div className='d-flex' >
                    <span className='my-auto text-medium'>دریافت میکنم</span>
                    <span className={this.state.c2.baseCurrency+ '-icon mr-2'} ></span>

                    <span className='ml-1 mr-auto my-auto text-medium'>
                      {this.state.c2.baseCurrency}
                    </span>
                    <FaAngleDoubleDown size='0.7rem' className='text-muted font-weight-light d-none d-sm-block mr-auto mr-auto my-auto' />
                    </div>
              </DropdownToggle>
              <DropdownMenu right className='px-2 swap-dropdown bg-white '>
                <div className='px-3 bg-white market-input-dark d-flex' >
                <div className='d-flex'>
                </div>
                  <input type='search' value={this.state.sd2} onChange={this.serach2}  className='ltr form-control' />
                </div>
                <DropdownItem divider className='' />
                <DropdownItem  className='d-flex py-2' onClick={()=>this.setbase(this.state.irr)} >
                <span className='ml-5'>
                  <span className='irr-icon' ></span>
                </span>
                <span className='ml-2 '>
                <NumericFormat value={1}   thousandSeparator="," displayType="text"/>
                </span>
                <span className='mr-auto '>
                  IRR
                </span>

                </DropdownItem>

                <div className='historylist-container custom-overflow'>
                {d2.map(item=>
                  <DropdownItem  className=' d-flex py-2' onClick={()=>this.setbase(item)} >
                  <span className='ml-5'>
                    <span className={item.baseCurrency+ '-icon'} ></span>
                  </span>
                  <span className='ml-2 '>
                  <NumericFormat value={item.irsell}   thousandSeparator="," displayType="text"/>
                  </span>
                  <span className='mr-auto '>
                    {item.baseCurrency}
                  </span>

                  </DropdownItem>
                )}
                </div>
              </DropdownMenu>
                </UncontrolledDropdown>
              <NumberFormat
                className='swap-input form-control'
                name='baseamount'
                value ={this.state.res}
                ref="baseamount"
                thousandSeparator=','
                disabled
                />


              </InputGroup>
              <div className='d-flex'>
              {this.state.c1.baseurrency === 'IRR' ? <small className='text-primary rtl' >{this.state.x}</small>: <></> }

              </div>
              </FormGroup>
            </div>
            </Col>
          </Row>
          </Col>


        </Row>
        <Row className='text-center home-slider text-light'>
          <Col lg={12} xl={{size:10 , offset :1, order:2}} md={12} className='h-300 mx-auto py-5 mb-5' >

            <Carousel showThumbs={false} transitionTime={1000} emulateTouch={true} showArrows={false} stopOnHover={true} swapable={true} infiniteLoop autoPlay={true}  interval={5000}>
              <Slide3/>
              <Slide1/>
              <Slide2/>
              <Slide4/>
              <Slide5/>
              <Slide6/>
              </Carousel>

              </Col>
        </Row>

        <Row className='text-center xs-px-2'>
          <Col lg={12} xl={{size:10 , offset :2, order:2}} md={12} className='mx-auto pt-3 pb-5' >

          <div className='font-weight-bold w-100 mt-5 p-2'>
            <div className='d-flex cr-row-header'>
              <div className='cr-col-0' ><p className='py-3'>{t('token')}</p> </div>
              <div onClick={()=>this.filtered('1')} className='cr-col-3' ><p className='py-3'>{t('buyprice')}</p></div>
              <div onClick={()=>this.filtered('2')} className='cr-col-3' ><p className='py-3'>{t('sellprice')}</p></div>
              <div onClick={()=>this.filtered('4')} className='cr-col-4' ><p className='py-3'>{t('marketcap')}</p></div>
              <div onClick={()=>this.filtered('3')} className='cr-col-5' ><p className='py-3'>{t('changerate')}</p></div>
            </div>
          </div>
            <div className='w-100 p-2'>

            <div>
            {f.slice(this.state.start,this.state.next).map(i=>
              <div className='d-flex cr-row'>
                <div className='cr-col-1' >
                <p className={i.baseCurrency+'-icon icon '}></p>
                </div>
                <div className='cr-col-2' >
                <p className='mt-1'>{i.baseCurrency}</p>
                <p className='mt-1'>{i.name}</p>
                </div>
                <div className='cr-col-3' >
                <p className='ltr'><NumericFormat value={this.state.checked === true ? parseFloat(i.sell) : parseFloat(i.irsell)}  thousandSeparator="," displayType="text"  className='font-weight-bold  my-auto' /></p>
                <p className='mt-2 ltr'><NumericFormat value={parseFloat(i.sell)}  thousandSeparator="," prefix="$" displayType="text"  className='font-weight-bold' /></p>
                </div>
                <div className='cr-col-4' >
                <p className='ltr'><NumericFormat value={parseFloat(i.irbuy)}  thousandSeparator="," displayType="text"  className='font-weight-bold  my-auto' /></p>
                <p className='ltr mt-1'><NumericFormat value={parseFloat(i.buy)}  thousandSeparator="," prefix="$" displayType="text"  className='font-weight-bold mt-2' /></p>

                </div>
                <div className='cr-col-5' ><p className='py-3'><NumberFormat value={parseInt(i.volValue)}  thousandSeparator="," prefix="$" displayType="text"  /></p></div>
                <div className='cr-col-6' >
                <p className={i.changeRate > 0 ? "bdage-market-success" : "bdage-market-danger"}> %{F.floor((i.changeRate * 100),4 )}</p>
                </div>
                <div className='d-flex cr-col-2 rtl pr-2' >
                <Link to={'/panel/exchange/buy/'+ i.baseCurrency} className='pointer'>{t('buy')}</Link>
                <Link to={'/panel/exchange/sell/'+ i.baseCurrency} className='pointer mx-3'>{t('sell')}</Link>
                <Link to={'/trade/' + i.symbolName} className='ml-auto'>{t('trade')}</Link>
                </div>
              </div>
            )}
            <div className='d-flex'>
            <Pagination>
            <PaginationItem onClick={this.setpaginationfirst}>
                <PaginationLink href="#" >
                {t('first')}
                </PaginationLink>
              </PaginationItem>
              <PaginationItem onClick={this.setpaginationprevious}>
              <PaginationLink href="#" >
              {t('previous')}
              </PaginationLink>
              </PaginationItem>
              <PaginationItem>
              <PaginationLink className='d-flex my-auto'>
              <span className='my-auto d-block'>{this.state.start}</span>
              <span className='my-auto mx-1 d-block'>{t('till')}</span>
              <span className='my-auto d-block'>{this.state.next}</span>
                </PaginationLink>
              </PaginationItem>

              <PaginationItem onClick={this.setpaginationnext}>
              <PaginationLink href="#" >
              {t('next')}
              </PaginationLink>
              </PaginationItem>
              <PaginationItem onClick={this.setpaginationlast}>
              <PaginationLink href="#" >
              {t('last')}
              </PaginationLink>
              </PaginationItem>
            </Pagination>
            </div>
            </div>


            </div>
          </Col>

        </Row>

        <div className='xl-1 pt-5 xs-gold'>
        <Row className='rtl' xs='1' xl='2'>

          <Col xl='6' xs='12' >
            <div className='' data-aos-duration={500} data-aos-delay='500' data-aos="slide-left">
              <img src={gold} className='gold-image'/>
            </div>
            <div className='glaas-card' data-aos-duration={500} data-aos-delay='300' data-aos="slide-up" >
            <p className='my-auto glaas-card-span text-center'>PAXG

            </p>
            <h4 className='mt-5  ltr'>
            </h4>
            <div className='glaas-card-bg'>

            </div>
            </div>
            <div className='glaas-card-2' data-aos-duration={500} data-aos-delay='500' data-aos="slide-right" >
              <img src={paxg}   className='glass-img m-auto' />
            <div className='glaas-card-bg-2'>
            </div>
            </div>
            </Col>
            <Col xl='6'xs='12' className='d-none d-md-block'>
              <div className='p-5'>
                <h1 className='display-4  text-warning text-center'>معاملات جهانی طلا با رپیتکس
                </h1>
                <h1 className='display-6  text-center mt-5'>پرسودترین کامودیتی دنیا


                </h1>
                <h1 className='display-6  text-center mt-5'>
                  امن ترین ارز دیجیتال با پشتوانه طلا
                </h1>
              </div>

              <div className='text-center mx-auto pointer mt-5 radius'>
              <Link to='/trade/PAXG-USDT' >
                <div color='warning'  className='main-buttun btn btn-warning py-2 b-shadow'>
                  <div className='d-flex text-center'>
                    <span className='mr-auto ml-2 font-weight-bold my-auto'>همین الان معامله کنید
                    </span>
                    <span className='paxg-icon mr-auto my-auto'>
                    </span>
                  </div>
                </div>
                </Link>

              </div>
              <div className='text-center pointer mx-auto pointer mt-5 radius'>
              <Link to='PAXG_مارکت_طلا_در_صرافی_رپیتکس _PAXG_GOLD'>
                <div color='warning'  className='main-buttun btn btn-outline btn-dark py-2 b-shadow'>
                  <div className='d-flex text-center'>
                    <span className='mr-auto ml-2 font-weight-bold my-auto'>مطالعه بیشتر ...
                    </span>
                    <span className='paxg-icon mr-auto my-auto'>
                    </span>
                  </div>
                </div>
                </Link>

              </div>
            </Col>
        </Row>

        <div id='home04' className='py-5'>
        <Row xl='3' md='1' lg='3' sm='3' xs='1'>
        <Col data-aos="fade-zoom-in slide-up" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="600" id='home04-c1' xl='4' lg='4' md='12' sm='4' xs='12' className=''>
           <div className='text-center m-auto card-row-left b-shadow'>
              <div className='card-row-glass'/>
              <div className='security-card-logo' />
              <p>اولویت ما امنیت دارایی های شماست
              </p>
            <div>

            </div>
           </div>

          </Col>
          <Col
          data-aos-delay='600' data-aos="fade-zoom-in slide-up" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="600"
           id='home04-c2' xl='4' lg='4' md='12' sm='4' xs='12' className=''>

           <div className='text-center m-auto card-row-center b-shadow'  >
           <div className='card-row-glass'/>
            <div className='wallet-card-logo' data-aos-delay='900' data-aos="flip-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="600"
              />
            <div>
              <p>ساخت ولت های ارز دیجیتال با دسترسی مستقیم
              </p>
            </div>
           </div>
          </Col>
          <Col
          data-aos-delay='1000' data-aos="fade-zoom-in slide-up" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="600" id='home04-c3' xl='4' lg='4' md='12' sm='4' xs='12' className=''>
           <div className='text-center m-auto card-row-right b-shadow'>
           <div className='card-row-glass'/>
            <div className='piggy-card-logo' data-aos-delay='1000' data-aos="flip-right" data-aos-offset="10" data-aos-easing="ease-in-sine" data-aos-duration="600"
             />
            <div>
              <p>بهترین مکان برای نگهداری دارایی های دیجیتال
              </p>
            </div>
           </div>
          </Col>
        </Row>
        </div>
</div>

          </div>


          </>
        }

        </>
)
}
}
export default withRouter(withTranslation()(Homepage))
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,color, fill, payload,name,balance, percent, value ,index} = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
   <g>
     <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#8884d8">
       {payload.name}
     </text>
     <text x={cx} y={cy -20} dy={8} textAnchor="middle" fill="#8884d8">
       {` ${(percent * 100).toFixed(2)}%`}
     </text>
     <Sector
       cx={cx}
       cy={cy}
       innerRadius={innerRadius}
       outerRadius={outerRadius}
       startAngle={startAngle}
       endAngle={endAngle}
       fill="#8884d8"
     />
     <Sector
       cx={cx}
       cy={cy}
       startAngle={startAngle}
       endAngle={endAngle}
       innerRadius={outerRadius + 6}
       outerRadius={outerRadius + 10}
       fill="orange"
     />

</g>
);
};


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
